import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Constants
import { LOGIN } from '../../routing/constants';

// Components
import Logo from '../../components/Logo';

// Tracking
import { setTrackingUserId } from '../../tracking/trackPage';

const LogoutPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('auth');
        setTrackingUserId();
        navigate(LOGIN);
    }, [navigate])

    return <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Qeydiyyat səhifəsi</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <p className="text-sm text-gray-500">Çıxış edilir...</p>
        </div>
            
    </div>
}

export default LogoutPage;