import React from 'react';

// Components
import Modal from '../../components/Modal';

const TermsAndConditionsModal = ({onClose}) => {
    
    const body = <div className='overflow-auto h-72'>
        <div className="pl-2">
            <b className="text-md">Təhlükəsizlik və konfidensiallıq</b>
            <p className="text-sm ml-6">
                SahibiniTap - digər sosial platformalar kimi istifadəçilərin ünsiyyətinin rahatlığı üzərində çalışır.
                Bunun üçün könüllü olaraq istifadəçilərin bəzi şəxsi məlumatları toplanır.
                Bunlara <i>ad, soyad, dövlət qeydiyyat nişanı, mobil nömrə, email, şəkil</i> və s. daxildir.
            </p>
            <b className="text-md">Məlumatların gizlədilməsi</b>
            <p className="text-sm ml-6">Lakin, biz istifadəçilərin şəxsi məlumatlarını gizlətmək seçimi də qoyuruq ki, bunu <b>Əsas Menyuda / Tənzimləmələr bölməsində</b>
                &nbsp;idarə etmək mümkündür.
            </p>
            <b className="text-md">Texpasport</b>
            <p className="text-sm ml-6">
                Texpasport yalnız istifadəçinin həqiqətən qeydiyyat nişanının sahibi olduğunun yoxlanılması üçündür və digər istifadəçilər tərəfindən əlçatmaz və gizlidir.
            </p>
        </div>
    </div>

    return <Modal 
        title="Şərtlər və qaydalar"
        body={body}
        buttons={
            <>
                <button type="button" onClick={() => onClose(true)} className="inline-flex w-full justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto">Oxudum, qəbul edirəm</button>
                <button onClick={() => onClose(false)} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Bağla</button>
            </>
        }
    />
}

export default TermsAndConditionsModal;