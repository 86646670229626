import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import env from "react-dotenv";

// Components
import Avatar from "../../../../components/Avatar";
import SettingsPageTemplate from "../../SettingsPageTemplate";

// Api
import { deleteUserById2, getUserById2, putUserById2 } from "../../../../api/user/id";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";

// Constants
import { HOME } from "../../../../routing/constants";

const EditProfile2Page = () => {
  let { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch
  } = useForm();
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [responseData, setResponseData] = useState(false);
	const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await putUserById2(id, data);
      setResponseData(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (data) => {
    try {
      if (window.confirm('Silmək istədiyinizdən əminsinizmi?') === true) {
        setIsDeleting(true);
        const response = await deleteUserById2(id);

        if (response?.success) {
          navigate('/search2');
        }
        setIsDeleting(false);
      }
    } catch (e) {
      console.log(e);
      alert(e?.message);
    }


    try {
      const response = await putUserById2(id, data);
      setResponseData(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getData = async () => {
    setIsPageLoading(true);
    try {
			const response = await getUserById2(id);
			setResponseData(response?.data);
			setIsPageLoading(false);
		} catch(e) {
			setIsPageLoading(false);
			if (e?.response?.status === 401) {
				navigate(HOME)
			}
		}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SettingsPageTemplate title="Profil məlumatları">
      {isPageLoading && <LoadingPlaceholder />}
      {!isPageLoading && responseData && <div className="grid max-w-2xl mx-auto mt-8">
        <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
          <div className="object-cover p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500">
            <Avatar size="md" img={responseData?.photo} />
          </div>
        </div>

        <form className="items-center mt-4 sm:mt-7 text-[#202142]" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center w-full mb-0 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                dövlət qeydiyyat nişanı
              </span>
              <div className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 ">
                {responseData?.plateNumber}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full mb-0 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                Texpasport
              </span>
              <Link target="_blank" to={env.S3_PATH + responseData?.doc1} className="p-2 inline-block border-indigo-500">
                <img className="w-32 h-32" src={env.S3_PATH + responseData?.doc1} alt="doc1"/>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center w-full mb-0 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                ad soyad
              </span>
              <div
                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
              >{responseData?.name}</div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                mobil
              </span>
              <div className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 ">
                {responseData?.phone}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
             <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                email
              </span>
              <div className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 ">
                {responseData?.email}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="">
             <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                verification
              </span>
              <select
                {...register("verification", {required: true})}
                defaultValue={responseData?.verification}
                className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <option value="not_defined">Not Defined</option>
                <option value="sent">Sent</option>
                <option value="rejected">Rejected</option>
                <option value="approved">Approved</option>
              </select>
            </div>
            {watch("verification") === 'rejected' && <div className="">
             <span className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white">
                reject reason
              </span>
              <select
                {...register("rejectReason", {required: true})}
                defaultValue={responseData?.verification}
                className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <option value="both_image_incorrect">Incorrect Photo</option>
                <option value="both_image_not_clear">Photo is not clear</option>
                <option value="plateNumber_image_not_match">Number/image not matching</option>
              </select>
            </div>}
          </div>

          <div className="flex justify-end mt-6 sm:mt-0">
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              type="submit"
              className={`${!isDirty || !isValid || isSubmitting ? 'disabled:bg-gray-400 hover:bg-gray-400' : ''} text-white bg-indigo-700  hover:bg-indigo-800 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
            >
              {isSubmitting ? 'Gözləyin...' : 'Yadda saxla'}
            </button>
          </div>
          <div className="flex justify-end mt-6 sm:mt-0">
            <button
              disabled={isDeleting}
              onClick={handleDelete}
              type="button"
              className="text-white bg-indigo-700  hover:bg-indigo-800 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            >
              {isDeleting ? 'Gözləyin...' : 'Sil'}
            </button>
          </div>
        </form>
      </div>}
    </SettingsPageTemplate>
  );
};

export default EditProfile2Page;
