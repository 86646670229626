import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// Api
import { getPosts } from "../../api/post";

// Components
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import Posts from "./Posts";
import BottomNavigation from "../../components/BottomNavigation";
import { likePost } from "../../api/post/likes";
import { NavLink } from "react-router-dom";
import { NEW_POST } from "../../routing/constants";

const PostsPage = () => {
  const [responseData, setResponseData] = useState([]);
  const [isLoadingMoreDisabled, setIsLoadingMoreDisabled] = useState(false);

  const getData = async () => {
    try {
      const response = await getPosts();
      setResponseData([...response?.data]);
      setIsLoadingMoreDisabled(false);
    //   navigation.navigate('Posts', { forceReload: false });
    } catch (error) {
      console.log(error);
    }
  };

  
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = async () => {
    const lastPostCreatedAt = responseData?.at(-1)?.createdAt;
    if (!lastPostCreatedAt || isLoadingMoreDisabled) { return; }
    const response = await getPosts(lastPostCreatedAt);
    if (response?.data?.length < 5) { setIsLoadingMoreDisabled(true); }
    setResponseData([...responseData, ...response?.data]);
  };

  const handleLike = async (postId) => {
    setResponseData([
      ...responseData?.map((post) => {
        return post?._id === postId
          ? {
              ...post,
              likesCount:
                post?.hasLiked === true
                  ? post?.likesCount - 1
                  : post?.likesCount + 1,
              hasLiked: !post?.hasLiked,
            }
          : post;
      }),
    ]);
    try {
      await likePost(postId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="min-h-screen bg-indigo-800">
      <main>
        <div className="mx-auto max-w-4xl py-16 pt-8 px-4 lg:px-8">
          <h2 className="flex justify-between pl-3 text-white text-2xl font-semibold">
            Elanlar

			<NavLink to={NEW_POST} className="pr-3">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
				</svg>
			</NavLink>

          </h2>

          <InfiniteScroll
            dataLength={responseData?.length || 0} //This is important field to render the next data
            next={loadMore}
            hasMore={!isLoadingMoreDisabled}
            loader={<div className="py-5">
				<LoadingPlaceholder />
			</div>}
          >
            <Posts items={responseData} onLike={handleLike} />
          </InfiniteScroll>
        </div>
      </main>
      <BottomNavigation />
    </div>
  );
};

export default PostsPage;
