import React from 'react';

const LoadingPlaceholder = () => {
    return <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
        <div className="h-4 my-1.5 bg-slate-200 rounded"></div>
        <div className="space-y-3">
            <div className="grid grid-cols-3 gap-10">
                <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                <div className="h-4 bg-slate-200 rounded col-span-2"></div>
            </div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="grid grid-cols-3 gap-10">
                <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                <div className="h-4 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-4 bg-slate-200 rounded"></div>
        </div>
        </div>
    </div>;
}

export default LoadingPlaceholder;
