import axios from 'axios';

export const getComments = async (postId) => {
  const response = await axios.get(`/posts/${postId}/comments`);

  return response.data;
};

export const createComment = async (postId, data) => {
  const response = await axios.post(`/posts/${postId}/comments`, data);

  return response.data;
};

export const deleteComment = async (postId, commentId) => {
  const response = await axios.delete(`/posts/${postId}/comments/${commentId}`);

  return response.data;
};
