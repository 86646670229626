import React from 'react';
import moment from "moment-with-locales-es6";
import { NavLink } from 'react-router-dom';

// Components
import Avatar from '../../../components/Avatar';
import VerificationBadge from '../../../components/VerificationBadge';

// Constants
import { MY_PROFILE } from '../../../routing/constants';

const Comment = ({comment, onLike}) => {
    return <li
        key={comment._id}
        className="
            relative block gap-x-6 py-1 px-4 cursor-pointer bg-white
            my-2 rounded-md
        "
    >
        <NavLink to={`${MY_PROFILE}/${comment?.userId?._id}`} className="flex gap-x-4 items-center w-full">
            <Avatar img={comment?.userId?.photo} />
            <div className="flex flex-col flex-grow align-middle">
                <span className="flex flex-row text-sm text-gray-900 font-semibold">
                    {comment?.userId?.plateNumber}{' '}
                    <VerificationBadge classNames="self-center -mt-1 ml-1" verification={comment?.userId?.verification} />
                    <div className="w-1 h-1 rounded-full bg-gray-400 opacity-50 mr-3 ml-3 self-center"/>
                    <span className="self-center text-xs text-gray-400">{moment(comment?.createdAt).locale("az").fromNow()}</span>
                </span>
                <span className="italic text-sm">{comment?.comment}</span>
            </div>
        </NavLink>
    </li>
};

export default Comment;