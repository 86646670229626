import axios from 'axios';

export const getUserMe = async () => {
  const response = await axios.get('user/me');

  return response.data;
};

export const putUserMe = async data => {
  const response = await axios.put('/user/me', data);

  return response.data;
};
