import axios from 'axios';

export const getMessages = async (id) => {
  const response = await axios.get(`messages/${id}`);

  return response.data;
};

export const createMessage = async (id, data) => {
  const response = await axios.post(`messages/${id}`, data);

  return response.data;
};

