// Pages
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import LoginAdminPage from "../pages/LoginAdminPage";
import LogoutPage from "../pages/LogoutPage";
import RegistrationPage from "../pages/RegistrationPage";
import DocumentConfirmationPage from "../pages/DocumentConfirmationPage";
import HomePage from "../pages/HomePage";
import SearchPage from "../pages/SearchPage";
import Search2Page from "../pages/Search2Page";
import EditProfile2Page from "../pages/SettingsPage/pages/EditProfile2Page";
import MessagesPage from "../pages/MessagesPage";
import ProfilePage from "../pages/ProfilePage";
import EditProfilePage from "../pages/SettingsPage/pages/EditProfilePage";
import ChangePasswordPage from "../pages/SettingsPage/pages/ChangePasswordPage";
import VerificationPage from "../pages/SettingsPage/pages/VerificationPage";
import NotificationsPage from "../pages/NotificationsPage";
import FavoritesPage from "../pages/FavoritesPage";

// Constants
import {
  CHANGE_PASSWORD,
  COMMENTS,
  DOCUMENT_CONFIRMATION,
  EDIT_PROFILE2,
  FAVORITES,
  HOME,
  LOGIN,
  LOGIN_ADMIN,
  LOGOUT,
  MESSAGES,
  MESSAGES_ID,
  MY_PROFILE,
  NEW_POST,
  NOTIFICATIONS,
  POSTS,
  PROFILE,
  REGISTRATION,
  SEARCH,
  SEARCH2,
  SETTINGS,
  VERIFICATION,
} from "./constants";
import PostsPage from "../pages/PostsPage";
import CommentsPage from "../pages/PostsPage/Comments";
import NewPostPage from "../pages/PostsPage/NewPost";

const routes = [
  {
    exact: true,
    path: HOME,
    element: <LandingPage />,
    elementForAuthenticated: <HomePage />,
  },
  {
    path: LOGIN,
    element: <LoginPage />,
    redirectUrlForAuthenticated: HOME,
  },
  {
    path: LOGIN_ADMIN,
    element: <LoginAdminPage />
  },
  {
    path: LOGOUT,
    element: <LogoutPage />,
    isPrivate: true,
  },
  {
    path: REGISTRATION,
    element: <RegistrationPage />,
    redirectUrlForAuthenticated: HOME,
  },
  {
    path: DOCUMENT_CONFIRMATION,
    element: <DocumentConfirmationPage />,
    isPrivate: true,
  },
  {
    path: SEARCH,
    element: <SearchPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: SEARCH2,
    element: <Search2Page />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: EDIT_PROFILE2,
    element: <EditProfile2Page />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: MESSAGES,
    element: <MessagesPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: MESSAGES_ID,
    element: <MessagesPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: POSTS,
    element: <PostsPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: COMMENTS,
    element: <CommentsPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: NEW_POST,
    element: <NewPostPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: NOTIFICATIONS,
    element: <NotificationsPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: FAVORITES,
    element: <FavoritesPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: MY_PROFILE,
    element: <ProfilePage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: PROFILE,
    element: <ProfilePage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    exact: true,
    path: SETTINGS,
    element: <EditProfilePage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: VERIFICATION,
    element: <VerificationPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: CHANGE_PASSWORD,
    element: <ChangePasswordPage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
  {
    path: "*",
    element: <HomePage />,
    isPrivate: true,
    isVerifyRequired: true,
  },
];

export default routes;
