import React, { useEffect, useState } from 'react';

// Api
import { getNotifications } from '../../api/notifications';

// Components
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import NotificationResults from './NotificationResults';
import BottomNavigation from '../../components/BottomNavigation';


const NotificationsPage = () => {
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [responseData, setResponseData] = useState(false);

	const getData = async () => {
		setIsPageLoading(true);
        const response = await getNotifications();
        setResponseData(response?.data);
		setIsPageLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return <div className="min-h-full">
	  	<main>
			<div className="mx-auto max-w-4xl py-16 pt-8 px-4 lg:px-8">
				<h2 className="pl-3 mt-4 sm:mt-0 sm:block mb-4 text-2xl font-semibold">Bildirişlər</h2>
		  		{isPageLoading ? <div className="py-5"><LoadingPlaceholder /></div> : <NotificationResults items={responseData} />}
			</div>
	  	</main>
		<BottomNavigation />
  </div>;
}

export default NotificationsPage;