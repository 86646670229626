import axios from 'axios';

export const registerUser = async ({plateNumber, phone, name, password, captchaToken}) => {
  const response = await axios.post('/auth/register', {
    plateNumber,
    phone,
    name,
    password,
    captchaToken
  });

  return response;
};

export const loginUser = async (plateNumber, password, captchaToken) => {
  const response = await axios.post('/auth/login', {
    plateNumber,
    password,
    captchaToken
  });

  return response;
};

export const loginUserAdmin = async (id) => {
  const response = await axios.get(`/auth/login-admin/${id}`);

  return response;
};
