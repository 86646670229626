import axios from 'axios';

export const searchUser = async (params) => {
  const response = await axios.get('user/search', {
    params
  });

  return response.data;
};

export const search2User = async (params) => {
  const response = await axios.get('user/', {
    params
  });

  return response.data;
};
