import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

// Components
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import ConversationList from "./ConversationList";
import Conversation from "./Conversation";
import BottomNavigation from "../../components/BottomNavigation";

// Api
import { getUserById } from "../../api/user/id";

const MessagesPage = () => {
  let { id } = useParams();
  const { state } = useLocation();
  const [receiverData, setReceiverData] = useState({
    ...state?.receiver,
  });
  const [isPageLoading, setIsPageLoading] = useState(false);

  const getData = async () => {
	setIsPageLoading(true);
    const response = await getUserById(id);
    setIsPageLoading(false);
    setReceiverData(response?.data);
  };

  useEffect(() => {
    if (!id) setReceiverData(null);
    if (id && !state?.receiver) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, state]);

  return (
    <div className="min-h-full">
      {/* <Navbar /> */}
      <div className="pt-0 sm:pt-0 bg-white w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
        <aside className="pt-4 md:w-1/2 lg:w-1/3 md:block">
          <div className={`${!id ? '' : 'hidden'} sm:flex sticky flex-col gap-2 p-4 text-sm border-0 border-indigo-100 md:border-r`}>
            <h2 className="pl-3 mt-4 sm:mt-0 sm:block mb-4 text-2xl font-semibold">
              Mesajlar
            </h2>
            <ConversationList receiver={receiverData} />
          </div>
        </aside>
        <main className="w-full py-1 md:w-2/3 lg:w-3/4">
          {isPageLoading && <LoadingPlaceholder />} 
          {!isPageLoading && receiverData?._id && (
            <Conversation receiver={receiverData} />
          )}
        </main>
      </div>
      <BottomNavigation />
    </div>
  );
};

export default MessagesPage;
