import { MY_PROFILE, POSTS, VERIFICATION } from "../../../routing/constants";
import {
  NOTIFICATION_TYPE_COMMENTED_YOUR_POST,
  NOTIFICATION_TYPE_LIKED_YOUR_POST,
  NOTIFICATION_TYPE_MULTIPLE_USERS_SEARCHED_FOR_YOU,
  NOTIFICATION_TYPE_MULTIPLE_USERS_VISITED_YOU,
  NOTIFICATION_TYPE_USER_JOINED_FOR_FAVORITES,
  NOTIFICATION_TYPE_USER_VERIFICATION_APPROVED,
  NOTIFICATION_TYPE_USER_VERIFICATION_REJECTED,
} from "./notificationTypeMessages";

const notificationTypeLinkData = {
  [NOTIFICATION_TYPE_MULTIPLE_USERS_SEARCHED_FOR_YOU]: () => ({}),
  [NOTIFICATION_TYPE_MULTIPLE_USERS_VISITED_YOU]: () => ({}),
  [NOTIFICATION_TYPE_USER_JOINED_FOR_FAVORITES]: ({ userId }) => ({
    to: `${MY_PROFILE}/${userId?._id}`
  }),
  [NOTIFICATION_TYPE_USER_VERIFICATION_APPROVED]: () => ({
    to: `${VERIFICATION}/`
  }),
  [NOTIFICATION_TYPE_USER_VERIFICATION_REJECTED]: () => ({
    to: `${VERIFICATION}/`
  }),
  [NOTIFICATION_TYPE_LIKED_YOUR_POST]: ({values}) => ({
    to: `${POSTS}/${values?.postData?._id}`
  }),
  [NOTIFICATION_TYPE_COMMENTED_YOUR_POST]: ({values}) => ({
    to: `${POSTS}/${values?.postData?._id}`
  }),
};

export default notificationTypeLinkData;
