import axios from 'axios';

const setAxiosDefaults = API_URL => {
  axios.defaults.baseURL = API_URL;

  axios.interceptors.request.use(
    async config => {
      const token = localStorage.getItem('auth');
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};

export default setAxiosDefaults;
