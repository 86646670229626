import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Api
import { searchUser } from '../../api/user/search';

// Components
import SearchResults from './SearchResults';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import VerificationModal from './VerificationModal';
import BottomNavigation from '../../components/BottomNavigation';

const SearchPage = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [isVerificationModalHidden, setIsVerificationModalHidden] = useState(false);
	const [responseData, setResponseData] = useState(false);

	const getData = async () => {
		setIsPageLoading(true);
        const response = await searchUser({ plateNumber: searchParams.get('plateNumber') });
        setResponseData(response);
		setIsPageLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get('plateNumber')])

	return <div className="min-h-full">
		<div className="mx-auto max-w-4xl pt-4 px-2 lg:px-8">
          <h2 className="flex align-middle pl-3 mb-4 text-black text-2xl font-semibold">
            <button className="mr-2" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </button>{" "}
            Axtarış
          </h2>
        </div>
	  	<main className="px-2">
			{responseData?.statusCode === 2 && !isVerificationModalHidden && <VerificationModal onClose={() => setIsVerificationModalHidden(true)} />}
			{responseData?.statusCode !== 2 && <div className="mx-auto max-w-4xl py-5 lg:px-8">
		  		{isPageLoading ? <LoadingPlaceholder /> : <SearchResults items={responseData?.data} />}
			</div>}
	  	</main>
		<BottomNavigation />
  </div>;
}

export default SearchPage;