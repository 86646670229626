import React from "react";
import { Navigate, useLocation } from "react-router-dom";
// import {jwtDecode} from 'jwt-decode';

// Constants
import {
    // DOCUMENT_CONFIRMATION,
    LOGIN
} from "./constants";

const AuthCheck = ({ children, isPrivate, elementForAuthenticated, isVerifyRequired }) => {
    const auth = localStorage.getItem('auth');
    let location = useLocation();


    if (isPrivate && !auth) {
        return <Navigate
            to={{
                pathname: LOGIN,
                state: { from: location }
            }}
        />;
    }

    if (elementForAuthenticated && auth) {
        return elementForAuthenticated;
    }

    // if (isVerifyRequired && auth) {
    //     const decoded = jwtDecode(auth);
    //     if (decoded?.verification !== 'approved') {
    //         return <Navigate
    //             to={{
    //                 pathname: DOCUMENT_CONFIRMATION,
    //                 state: { from: location }
    //             }}
    //         />;
    //     }
    // }

    return children;
}

export default AuthCheck;
