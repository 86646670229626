import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import InputMask from 'react-input-mask';

// Api
import { search2User } from '../../api/user/search';

// Components
import Navbar from '../../components/Navbar';
import Search2Results from './Search2Results';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { HOME } from '../../routing/constants';

const Search2Page = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [responseData, setResponseData] = useState(false);
	const [count, setCount] = useState(0);
	const { register, handleSubmit, control } = useForm();
	const navigate = useNavigate();

	const onSubmit = async data => {
		setSearchParams({
			verification: data?.verification,
			plateNumber: data?.plateNumber?.toUpperCase(),
			email: data?.email,
			phone: data?.phone,
			platform: data?.platform
		})
	};

	const getData = async () => {
		setIsPageLoading(true);

		try {
			const response = await search2User({
				verification: searchParams.get('verification'),
				plateNumber: searchParams.get('plateNumber'),
				email: searchParams.get('email'),
				phone: searchParams.get('phone'),
				platform: searchParams.get('platform')
			});
			setResponseData(response?.data);
			setCount(response?.count);
			setIsPageLoading(false);
		} catch(e) {
			setIsPageLoading(false);
			if (e?.response?.status === 401) {
				navigate(HOME)
			}
		}
    }

	const useEffectDependencies = [
		searchParams.get('verification'),
		searchParams.get('plateNumber'),
		searchParams.get('email'),
		searchParams.get('phone'),
		searchParams.get('platform'),
	];

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, useEffectDependencies)

	return <div className="min-h-full">
	  	<Navbar />
	  	<header className="pt-16 bg-white shadow">
			<div className="mx-auto px-4 py-6 max-w-4xl sm:px-6 lg:px-8">
				<form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
					<div className="relative flex gap-3 flex-col md:flex-row text-indigo-300 focus-within:text-indigo-500">
						<Controller
							control={control}
							name="plateNumber"
							render = {()=> (
								<InputMask
									{...register("plateNumber", {required: false})}
									mask="99-aa-999"
									id="plateNumber"
									placeholder={searchParams.get('plateNumber') || 'plateNumber'}
									className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 uppercase"
									autoComplete="off"
								/>
							)}
						/>

						<select
							{...register("verification", {required: false})}
							defaultValue={searchParams.get('verification')}
							className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
							<option value="">Verification</option>
							<option value="not_defined">Not Defined</option>
							<option value="sent">Sent</option>
							<option value="rejected">Rejected</option>
							<option value="approved">Approved</option>
						</select>

						<select
							{...register("platform", {required: false})}
							defaultValue={searchParams.get('platform')}
							className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6">
							<option value="">Platform</option>
							<option value="web">web</option>
							<option value="ios">ios</option>
							<option value="android">android</option>
						</select>

						<input
							{...register("email", {required: false})}
							mask=""
							id="email"
							placeholder={searchParams.get('email') || 'email'}
							className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							autoComplete="off"
						/>

						<Controller
							control={control}
							name="phone"
							render = {()=> (
								<InputMask
									{...register("phone", {required: false})}
									mask="099-999-9999"
									id="phone"
									placeholder={searchParams.get('phone') || 'phone'}
									className="pl-10 bg-indigo-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 uppercase"
									autoComplete="off"
								/>
							)}
						/>

					</div>
					<span className="inset-y-0 right-0 flex items-center pr-1 w-full">
						<button type="submit" className="w-full bg-indigo-500 hover:bg-indigo-700 text-white font-semibold text-sm py-1 px-4 rounded">
							Axtar
						</button>
					</span>
				</form>
			</div>
	  	</header>
	  	<main>
			<div className="mx-auto max-w-4xl py-5 lg:px-8">
		  		{isPageLoading ? <LoadingPlaceholder /> : <Search2Results count={count} items={responseData} />}
			</div>
	  	</main>
  </div>;
}

export default Search2Page;