import React from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

// Components
import Modal from '../../components/Modal';

// Constants
import { VERIFICATION } from '../../routing/constants';

const VerificationModal = ({onClose}) => {
    const decoded = jwtDecode(localStorage.getItem('auth'));
    
    const body = <div className="mt-6">
        <div className="mb-2 rounded text-yellow-900 px-6 py-5 text-base bg-yellow-200" role="alert">
            <span className="inline-block align-middle">
                <b>{decoded?.plateNumber}</b> həqiqi sahibi olduğunuzu göstərən "Təsdiq Nişanı"<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-green-700 inline-block align-middle">
                    <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                </svg>-nı əldə edə bilərsiz.
            </span>
        </div>
    </div>

    return <Modal 
        title="Təsdiq Nişanı"
        body={body}
        buttons={
            <>
                <Link to={VERIFICATION} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto">Təsdiq et</Link>
                <button onClick={onClose} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Sonra</button>
            </>
        }
    />
}

export default VerificationModal;