import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// API
import { getConversations } from "../../../api/message/conversations";

// Components
import Avatar from "../../../components/Avatar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import VerificationBadge from "../../../components/VerificationBadge";

// Constants
import { MESSAGES } from "../../../routing/constants";

const ConversationList = ({ receiver }) => {
  const decoded = jwtDecode(localStorage.getItem("auth"));
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
    const response = await getConversations();
    setIsPageLoading(false);
    setResponseData(response?.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let newConversation = [];
  if (receiver?._id && !responseData?.filter(item => item._id === receiver?._id).length > 0) {
    newConversation.push({ _id: receiver?._id, user: {_id: decoded?._id }, receiver})
  }

  if (!isPageLoading && !newConversation?.length && !responseData?.length) {
    return <div
      className="mt-4 rounded bg-indigo-100 px-6 mx-4 py-5 text-base text-indigo-700"
      role="alert"
    >
        Sizin heç bir mesajınız yoxdur.
    </div>
  }

  return (
    <ul className="block border-y mt-4 divide-y divide-gray-100">
      {isPageLoading && <LoadingPlaceholder />}
      {!isPageLoading &&
        Array.isArray(responseData) &&
        [...newConversation, ...responseData]?.map((conversation) => {
          const userData =
            conversation?.user?._id === decoded._id
              ? conversation?.receiver
              : conversation?.user;

          return (
            <NavLink
              to={`${MESSAGES}/${conversation?._id}`}
              state={{ receiver: conversation?.userData }}
              key={conversation?._id}
            >
              <li
                key={conversation._id}
                className={`flex justify-between gap-x-6 py-5 px-4 hover:bg-indigo-50 cursor-pointer ${receiver?._id === conversation?._id ? 'bg-indigo-50' : ''} `}
              >
                <div
                  to={`/messages/${conversation._id}`}
                  className="flex min-w-0 gap-x-4"
                >
                  <Avatar img={userData?.photo} />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {userData?.plateNumber} <VerificationBadge classNames="-mt-1" verification={userData?.verification} />
                    </p>
                    <p className="truncate text-xs leading-5 text-gray-500">
                      {conversation.message}
                    </p>
                  </div>
                </div>
              </li>
            </NavLink>
          );
        })}
    </ul>
  );
};

export default ConversationList;
