import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import routes from './routes';
import AuthCheck from "./AuthCheck";

const AppRoutes = () => {
    return <Router>
        <Routes>
            {routes.map(({element, isPrivate, redirectUrlForAuthenticated, elementForAuthenticated, isVerifyRequired, path, ...rest}) =>  
                <Route
                    {...rest}
                    key={path}
                    path={path}
                    element={
                        <AuthCheck
                            isPrivate={isPrivate}
                            redirectUrlForAuthenticated={redirectUrlForAuthenticated}
                            elementForAuthenticated={elementForAuthenticated}
                            isVerifyRequired={isVerifyRequired}
                        >
                            {element}
                        </AuthCheck>
                    }
                />
            )}
        </Routes>
    </Router>;
}

export default AppRoutes;