import axios from 'axios';

export const getVerification = async () => {
  const response = await axios.get('user/verification');

  return response.data;
};

export const putVerification = async formData => {
  const response = await axios.put('user/verification', formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  });

  return response.data;
};
