import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import moment from "moment-with-locales-es6";
import { ToastContainer, toast } from 'react-toastify';


// Components
import Navbar from "../../components/Navbar";
import LoadingPlaceholder from "../../components/LoadingPlaceholder";
import Avatar from "../../components/Avatar";
import VerificationBadge from "../../components/VerificationBadge";
import FavoriteBadge from "../../components/FavoriteBadge";
import BottomNavigation from "../../components/BottomNavigation";

// Api
import { getUserById } from "../../api/user/id";
import { createFavorite, deleteFavorite } from "../../api/favorites";

// Constants
import { MESSAGES } from "../../routing/constants";

const ProfilePage = () => {
  let { id } = useParams();
  const decoded = jwtDecode(localStorage.getItem("auth"));
  const isMyProfile = !id || id === decoded?._id;
  const [responseData, setResponseData] = useState(
    isMyProfile && { ...decoded }
  );
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFavorited, setIsFavorited] = useState(false);

  const handleClickFavorite = async () => {
    try {
      if (isFavorited) {
        await deleteFavorite(id);
        toast('Favoritlərdən silindi!', {
          icon: <FavoriteBadge isFavorited={true} />
        });
      } else {
        toast('Favoritlərə əlavə olundu!', {
          icon: <FavoriteBadge />
        })
        await createFavorite(id);
      }
    } catch (e) {
      console.log(e);
    }
    setIsFavorited(!isFavorited);
  };

  const getData = async () => {
    const response = await getUserById(id);
    setIsPageLoading(false);
    setResponseData(response?.data);
    setIsFavorited(response?.isFavorite);
  };

  useEffect(() => {
    if (isMyProfile) {
      return setResponseData({ ...decoded });
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMyProfile]);

  return (
    <div className="min-h-full">
      <Navbar />
      <ToastContainer />
      {/* <main className="px-6 py-12"> */}
      <div className="pt-16 bg-white w-full flex z-0 flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
        <aside className="pt-4 md:w-1/2 lg:w-1/3 md:block z-0">
          <div className="flex flex-col gap-2 p-4 text-sm border-0 border-indigo-100 md:border-r">
            <div className="flex flex-col items-center space-y-5">
              <div className="object-cover p-1 rounded-full ring-2 ring-indigo-300 ">
                <Avatar size="md" img={responseData?.photo} />
              </div>

              {!isMyProfile && !isPageLoading && (
                <div className="flex flex-row gap-4">
                  {responseData?.plateNumber && (
                    <NavLink
                      to={`${MESSAGES}/${responseData?._id}`}
                      state={{ receiver: responseData }}
                      className="w-10 h-10 flex items-center justify-center font-medium text-indigo-900 focus:outline-none rounded-full border border-indigo-200 hover:bg-indigo-100 focus:z-10 focus:ring-4 focus:ring-indigo-200 "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                        <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                      </svg>
                    </NavLink>
                  )}
                  {responseData?.phone && (
                    <a
                      href={`tel:+994${responseData?.phone
                        ?.substring(1)
                        .split("-")
                        .join("")}`}
                      type="button"
                      className="w-10 h-10 flex items-center justify-center font-medium text-green-700 focus:outline-none bg-white rounded-full border border-indigo-200 hover:bg-indigo-100 hover:text-indigo-800 focus:z-10 focus:ring-4 focus:ring-indigo-200 "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  )}
                  <button
                    onClick={handleClickFavorite}
                    type="button"
                    className="w-10 h-10 flex items-center justify-center font-medium text-yellow-600 focus:outline-none bg-white rounded-full border border-indigo-200 hover:bg-indigo-100 hover:text-indigo-800 focus:z-10 focus:ring-4 focus:ring-indigo-200 "
                  >
                    <FavoriteBadge isFavorited={isFavorited} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </aside>
        <main className="w-full py-1 md:w-2/3 lg:w-3/4">
          <div>
            <div className="w-full px-6 pb-8 sm:max-w-xl sm:rounded-lg">
              <div className="grid max-w-2xl mx-auto">
                <div className="items-center sm:mt-5 text-[#202142]">
                  {isPageLoading && !isMyProfile ? <LoadingPlaceholder /> : ""}
                  {!isPageLoading || isMyProfile ? (
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-3 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Dövlət Qeydiyyat Nişanı
                        </dt>
                        <dd className="mt-1 text-lg leading-6 text-gray-700 sm:col-span-2 sm:mt-0 uppercase">
                          {responseData?.plateNumber}{" "}
                          <VerificationBadge
                            classNames="-mt-1"
                            verification={responseData?.verification}
                          />
                        </dd>
                      </div>

                      <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Ad, Soyad
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {responseData?.name}
                        </dd>
                      </div>

                      {responseData?.email && (
                        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {responseData?.email}
                          </dd>
                        </div>
                      )}

                      <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Qeydiyyat Tarixi
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {moment(responseData?.createdAt).locale('az').fromNow()}
                        </dd>
                      </div>
                    </dl>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* </main> */}
      <BottomNavigation />
    </div>
  );
};

export default ProfilePage;
