import React from 'react';
import ReactDOM from 'react-dom/client';
import env from "react-dotenv";
import { Provider, ErrorBoundary } from '@rollbar/react'

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (env.ENVIRONMENT === 'production') {
  const rollbarConfig = {
    accessToken: '9c74a10ab1c042e987b4ba81e3bf001b',
    environment: env.ENVIRONMENT,
  }

  root.render(
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ErrorBoundary>
    </Provider>
  );
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
