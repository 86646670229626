import axios from 'axios';

export const getUserById = async (id) => {
  const response = await axios.get(`user/${id}`);

  return response.data;
};

export const getUserById2 = async (id) => {
  const response = await axios.get(`user/2/${id}`);

  return response.data;
};

export const putUserById2 = async (id, data) => {
  const response = await axios.put(`user/2/${id}`, data);

  return response.data;
};

export const deleteUserById2 = async (id) => {
  const response = await axios.delete(`user/2/${id}`);

  return response.data;
};