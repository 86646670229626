import axios from 'axios';

export const putUserPhoto = async formData => {
  const response = await axios.put('user/photo', formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  });

  return response.data;
};

export const deleteUserPhoto = async () => {
  const response = await axios.delete('/user/photo');

  return response.data;
};
