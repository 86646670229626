import React from "react";
import { NavLink } from "react-router-dom";

// Components
import Post from "./Post";
import { POSTS } from "../../../routing/constants";

const Posts = ({ items, onLike }) => {
  if (!Array.isArray(items) || items?.length < 1) {
    return null;
  }

  return (
    <>
      <ul className="mt-4 divide-y divide-gray-100">
        {items?.filter(({type}) => type === undefined).map(item => {
          return (
              <NavLink
                to={`${POSTS}/${item?._id}`}
                state={{ postData: item }}
              >
                <Post
                  key={item?._id}
                  post={item} 
                  onLike={onLike}
                />
              </NavLink>
          );
        })}
      </ul>
    </>
  );
};

export default Posts;
