import axios from 'axios';

export const createFavorite = async (id) => {
  const response = await axios.post('favorites', { id });

  return response.data;
};

export const deleteFavorite = async (id) => {
  const response = await axios.delete('favorites', { data: {id} });
  return response.data;
};

export const getFavorites = async () => {
  const response = await axios.get('favorites');

  return response.data;
};
