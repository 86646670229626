export const HOME = '/';
export const LOGIN = '/login';
export const LOGIN_ADMIN = '/login-admin/:id';
export const LOGOUT = '/logout';
export const REGISTRATION = '/registration';
export const DOCUMENT_CONFIRMATION = '/document-confirmation';
export const SEARCH = '/search';
export const SEARCH2 = '/search2';
export const EDIT_PROFILE2 = '/edit-profile2/:id';
export const MESSAGES = '/messages';
export const MESSAGES_ID = '/messages/:id';
export const POSTS = '/posts';
export const NEW_POST = '/new-post';
export const COMMENTS = '/posts/:id';
export const NOTIFICATIONS = '/notifications';
export const FAVORITES = '/favorites';
export const MY_PROFILE = '/profile';
export const PROFILE = '/profile/:id';
export const SETTINGS = '/settings';
export const VERIFICATION = '/settings/verification';
export const CHANGE_PASSWORD = '/change-password';
