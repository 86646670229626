import React from 'react';
import moment from "moment-with-locales-es6";
import { NavLink } from 'react-router-dom';

// Components
import Avatar from '../../../components/Avatar';
import VerificationBadge from '../../../components/VerificationBadge';

// Constants
import { MY_PROFILE, POSTS } from '../../../routing/constants';

const Post = ({post, onLike}) => {
    const handleLike = e => {
        e.stopPropagation();
        e.preventDefault();

        onLike(post?._id);
    }

    return <li
        key={post._id}
        className="
            relative block gap-x-6 py-2 px-4 cursor-pointer bg-white
            my-2 rounded-md
        "
    >
        <div className="flex gap-x-4 items-center">
            <NavLink to={`${MY_PROFILE}/${post?.userId?._id}`}><Avatar img={post?.userId?.photo} /></NavLink>
            <div className="min-w-0 max-w-64 flex flex-col align-middle">
                <NavLink to={`${MY_PROFILE}/${post?.userId?._id}`} className="text-sm text-gray-900 font-semibold">{post?.userId?.plateNumber} <VerificationBadge classNames="-mt-1" verification={post?.userId?.verification} /></NavLink>
                <NavLink to={`${MY_PROFILE}/${post?.userId?._id}`} className="text-xs text-gray-400">{moment(post?.createdAt).locale("az").fromNow()}</NavLink>
            </div>
        </div>
        <div className="mt-2">
            {post?.content}
        </div>
        <div className="flex flex-row align-middle mt-3">
            <button
                className={`flex flex-row mr-3 ${post?.hasLiked ? 'text-red-500' : 'text-gray-700'}`}
                onClick={handleLike}
            >
                <svg className="mr-2 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill={post?.hasLiked ? 'currentColor' : 'none'} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                </svg>
                {post?.likesCount || '0'}
            </button>
            <NavLink
                to={`${POSTS}/${post?._id}`}
                state={{ postData: post }}
                className="flex flex-row text-gray-700 hover:text-gray-900"
            >
                <svg className="mr-2 w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                </svg>
                {post?.commentsCount || '0'}
            </NavLink>
        </div>
    </li>
};

export default Post;