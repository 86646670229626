import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Api methods
import { loginUserAdmin } from '../../api/authentication/register';

// Constants
import { HOME } from '../../routing/constants';

// Components
import Logo from '../../components/Logo';

const LoginAdminPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const getData = async () => {
        const response = await loginUserAdmin(id);

        if (response?.data?.token) {
            localStorage.setItem('auth', response?.data?.token);
            navigate(HOME);
        }
    }

    useEffect(() => {
        if (id) {
            getData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    return <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex items-center justify-center"><Logo isDark size="md" /></div>
            <h3 className="mt-10 text-center text-xl font-semibold leading-9 tracking-tight text-gray-700">Hesaba giriş səhifəsi</h3>
        </div>
    </div>
}

export default LoginAdminPage;