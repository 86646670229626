import React from "react";
import { NavLink } from "react-router-dom";

// Components
import Avatar from "../../../components/Avatar";
import VerificationBadge from "../../../components/VerificationBadge";

const SearchResults = ({ items }) => {
  if (!Array.isArray(items) || items.length < 1) {
    return (
      <>
        <div
          className="mt-4 rounded bg-indigo-100 px-6 py-5 text-base text-indigo-700"
          role="alert"
        >
          <span className="font-bold">Sahibi tapılmadı!</span> <br /> Təəssüf
          ki, bu Dövlət Qeydiyyat Nişanı sahibinin qeydiyyatı tapılmadı.
        </div>
      </>
    );
  }

  return (
    <>
      <div className="font-sans px-4">
        <b>{items.length}</b> Sahibi tapıldı.
      </div>
      <ul className="border-y mt-4 divide-y divide-gray-100">
        {Array.isArray(items) &&
          items?.map((item) => (
            <li
              key={item._id}
              className="flex justify-between gap-x-6 py-5 px-4 hover:bg-indigo-50 cursor-pointer"
            >
              <NavLink
                to={`/profile/${item._id}`}
                className="flex min-w-0 gap-x-4"
              >
                <Avatar img={item.photo} />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {item.plateNumber}{" "}
                    <VerificationBadge
                      classNames="-mt-1"
                      verification={item?.verification}
                    />
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {item.name}
                  </p>
                </div>
              </NavLink>
              <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                <NavLink
                  to={`/profile/${item._id}`}
                  className="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 pr-2 pl-4 rounded inline-flex items-center"
                >
                  <span className="mr-2">Profilə keç</span>
                  <svg
                    className="w-4 h-4 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 8 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                    ></path>
                  </svg>
                </NavLink>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default SearchResults;
