import axios from 'axios';

export const getPosts = async (lastPostCreatedAt) => {
  const response = await axios.get('/posts/', {
    params: {
      lastPostCreatedAt
    }
  });

  return response.data;
};

export const createPost = async (data) => {
  const response = await axios.post('/posts/', data);

  return response.data;
};

export const deletePost = async (postId) => {
  const response = await axios.delete(`/posts/${postId}`);

  return response.data;
};
