import React from 'react';
import env from "react-dotenv";

const Avatar = ({ size, img }) => {
    let divSize = 10;

    if (size === 'md') {
        divSize = 36
    }

    if (img) {
        return <img className={`object-cover w-${divSize} h-${divSize} rounded-full`} src={env.S3_PATH + img} alt="User avatar"/>
    }

    return <div className={`relative w-${divSize} h-${divSize} overflow-hidden bg-gray-100 rounded-full`}>
        <svg className={`absolute w-${divSize} h-${divSize} text-gray-400 left-auto -bottom-${Math.floor(divSize / 9)}`} fill="currentColor" viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd">
            </path>
        </svg>
    </div>;
}

export default Avatar;
