import React, { useEffect, useState } from 'react';

// Api
import { getFavorites } from '../../api/favorites';

// Components
import Navbar from '../../components/Navbar';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import FavoriteResults from './FavoriteResults';


const FavoritesPage = () => {
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [responseData, setResponseData] = useState(false);

	const getData = async () => {
		setIsPageLoading(true);
        const response = await getFavorites();
        setResponseData(response?.data);
		setIsPageLoading(false);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return <div className="min-h-full">
	  	<Navbar />
	  	<main>
			<div className="mx-auto max-w-4xl py-5 pt-16 px-4 lg:px-8">
		  		{isPageLoading ? <div className="py-5"><LoadingPlaceholder /></div> : <FavoriteResults items={responseData} />}
			</div>
	  	</main>
  </div>;
}

export default FavoritesPage;