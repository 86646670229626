import React, { useHistory, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";

// Api
import { likePost } from "../../api/post/likes";
import { createComment, getComments } from "../../api/post/comments";

// Components
import BottomNavigation from "../../components/BottomNavigation";
import Post from "./Posts/Post";
import Comment from "./Posts/Comment";

const CommentsPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    comment: yup.string().trim().required("Mətn tələb olunur"),
  });
  const { handleSubmit, register, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      comment: "",
    },
  });

  const getData = async () => {
    try {
      setIsLoading(true);

      const response = await getComments(id);
      if (response?.success) {
        setPost({ ...response?.post });
        setResponseData([...response?.data]);
      } else {
        alert("Elan tapılmadı");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // setPost({...route?.params?.postData})
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (data) => {
    if (isSubmitting) return false;

    setIsSubmitting(true);
    try {
      const response = await createComment(post?._id, data);
      if (response?.success) {
        reset();
        setResponseData([response?.data, ...responseData]);
      }
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
      alert("Rəyiniz paylaşılmadı. Az sonra yenidən cəhd edin.");
    }
  };

  const handleLike = async (postId) => {
    setPost(
      post?._id === postId
        ? {
            ...post,
            likesCount:
              post?.hasLiked === true
                ? post?.likesCount - 1
                : post?.likesCount + 1,
            hasLiked: !post?.hasLiked,
          }
        : post
    );
    try {
      await likePost(postId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="min-h-screen">
      <main className="mx-auto max-w-4xl pt-8 px-2 lg:px-8">
        <div className="border-b-2">
          <h2 className="flex align-middle pl-3 sm:mt-0 mb-4 text-black text-2xl font-semibold">
            <button className="mr-2" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </button>{" "}
            Rəylər
          </h2>
          <Post post={post} onLike={handleLike} />
        </div>
        <form className="px-2 py-2 relative" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("comment", { required: true })}
            type="text"
            id="name"
            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
            placeholder="Rəyinizi bura yazın..."
            required
          />
          <button
            type="submit"
            className="absolute right-4 top-4 text-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
          </button>
        </form>
        <ul className="pb-16 overflow-y-auto">
          {responseData?.map((comment) => (
            <Comment comment={comment} />
          ))}
        </ul>
      </main>
      <BottomNavigation />
    </div>
  );
};

export default CommentsPage;
