import React, { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useForm, Controller } from "react-hook-form";
import { jwtDecode } from 'jwt-decode';

// Components
import VerificationModal from './VerificationModal';
import BottomNavigation from '../../components/BottomNavigation';

// Constants
import { SEARCH } from '../../routing/constants';

const HomePage = () => {
	const decoded = jwtDecode(localStorage.getItem("auth"));
	const hasSeenVerificationModal = sessionStorage.getItem('hasSeenVerificationModal');
	const [isVerificationModalHidden, setIsVerificationModalHidden] = useState(!!hasSeenVerificationModal);
	const { register, handleSubmit, control } = useForm();
	const navigate = useNavigate();

	const onSubmit = async data => {
		navigate({
			pathname: SEARCH,
			search: `?${createSearchParams({
				plateNumber: data?.plateNumber?.toUpperCase()
			})}`
		});
	};

	const handleCloseVerificationModal = () => {
		setIsVerificationModalHidden(true);
		sessionStorage.setItem('hasSeenVerificationModal', true);
	}

	return <div className="min-h-full bg-indigo-800 h-screen">
		{
			!isVerificationModalHidden &&
			(!decoded?.verification || decoded?.verification === 'rejected') ?
			<VerificationModal onClose={handleCloseVerificationModal} /> : null
		}
	  	<main className="flex items-center flex-col min-h-full">
			<div className="text-6xl sm:text-7xl text-indigo-100 items-center my-20 font-semibold">
				SahibiniTap
			</div>
		  	<form className="relative text-indigo-300 focus-within:text-indigo-200 w-10/12 md:w-6/12 sm:w-7/12" onSubmit={handleSubmit(onSubmit)}>
				<span className="absolute inset-y-0 left-0 flex items-center pl-2">
					<span type="submit" className="p-1 focus:outline-none focus:shadow-outline">
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
					</span>
				</span>

				<Controller
					control={control}
					name="plateNumber"
					render = {()=> (
						<InputMask
							{...register("plateNumber", {required: true})}
							mask="99-aa-999"
							id="plateNumber"
							placeholder="10-AA-010"
							className="w-full pl-14 rounded-full border-0 py-5 border-indigo-400 bg-indigo-800 text-indigo-300 focus:text-indigo-100 text-2xl shadow-sm ring-1 ring-inset ring-indigo-400 placeholder:text-indigo-400 focus:ring-2 focus:ring-inset focus:ring-indigo-300 sm:leading-6 uppercase"
							autoComplete="off"
							required
						/>
					)}
				/>

				<span className="absolute inset-y-0 right-0 flex items-center pr-3">
					<button type="submit" className="bg-indigo-500 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-full">
						Axtar
					</button>
				</span>
			</form>
	  	</main>
		<BottomNavigation />
  </div>;
}

export default HomePage;