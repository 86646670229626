const messages = {
    'front_image_incorrect': 'Texpasportun ön tərəf şəkli uyğun deyil.',
    'back_image_incorrect': 'Texpasportun arxa tərəf şəkli uyğun deyil.',
    'both_image_incorrect': 'Texpasport şəkli uyğun deyil.',
    'front_image_not_clear': 'Texpasportun ön tərəf şəkli aydın görsənmir.',
    'back_image_not_clear': 'Texpasportun arxa tərəf şəkli aydın görsənmir.',
    'both_image_not_clear': 'Şəkillər aydın görsənmir.',
    'plateNumber_image_not_match': 'Texpasportdakı nömrə qeydiyyatdan keçdiyiniz ilə uyğun deyil.'
}

export default messages;
