import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment-with-locales-es6";

// utils
import notificationTypeLinkData from "../constants/notificationTypeLink";
import notificationTypeMessages from "../constants/notificationTypeMessages";

const NotificationResults = ({ items }) => {
  const notifications =
    Array.isArray(items) ?
    items?.filter((item) => {
      if (
        typeof notificationTypeMessages[item?.type] === "undefined" ||
        typeof notificationTypeLinkData[item?.type] === "undefined"
      ) {
        return false;
      }
      return true;
    }) : [];

  if (notifications.length < 1) {
    return (
      <div
        className="mt-4 rounded bg-indigo-100 px-6 mx-4 py-5 text-base text-indigo-700"
        role="alert"
      >
        Sizin heç bir bildirişiniz yoxdur.
      </div>
    );
  }

  return (
    <>
      <ul className="border-y mt-4 divide-y divide-gray-100">
        {notifications?.map(item => {
          return (
            <li
              key={item._id}
              className={`relative block gap-x-6 py-5 px-4 ${
                !item?.hasSeen ? "bg-indigo-50" : ""
              } hover:bg-indigo-50 cursor-pointer`}
            >
              <NavLink {...notificationTypeLinkData[item?.type](item)}>
                {notificationTypeMessages[item.type](item)}
                <span className="absolute right-1 top-1 text-xs text-gray-400">
                  {moment(item?.createdAt).locale("az").fromNow()}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default NotificationResults;
