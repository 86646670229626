import React from 'react';

// Components
import Avatar from '../../../components/Avatar';

/* eslint-disable no-template-curly-in-string */
export const NOTIFICATION_TYPE_MULTIPLE_USERS_SEARCHED_FOR_YOU = 'multiple_users_searched_for_you';
export const NOTIFICATION_TYPE_MULTIPLE_USERS_VISITED_YOU = 'multiple_users_visited_you';
export const NOTIFICATION_TYPE_USER_SEARCHED_FOR_YOU = 'user_searched_for_you';
export const NOTIFICATION_TYPE_USER_VISITED_YOU = 'user_visited_you';
export const NOTIFICATION_TYPE_USER_JOINED_FOR_FAVORITES = 'user_joined_for_favorites';
export const NOTIFICATION_TYPE_USER_VERIFICATION_APPROVED = 'user_verification_approved';
export const NOTIFICATION_TYPE_USER_VERIFICATION_REJECTED = 'user_verification_rejected';
export const NOTIFICATION_TYPE_LIKED_YOUR_POST =
  'liked_your_post';
export const NOTIFICATION_TYPE_COMMENTED_YOUR_POST =
  'commented_your_post';

const notificationTypeMessages = {
    [NOTIFICATION_TYPE_MULTIPLE_USERS_SEARCHED_FOR_YOU]: ({values, receiverId}) => 
        <div className="flex gap-x-4 items-center">
            <Avatar img={receiverId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900">Sizin <span className="font-bold">{receiverId?.plateNumber}</span> avtomobil nömrəniz <span className="font-bold">{values?.count}</span> dəfə axtarılmışdır.</p>
            </div>
        </div>,
    [NOTIFICATION_TYPE_USER_JOINED_FOR_FAVORITES]: ({userId}) => 
        <div className="flex gap-x-4 items-center">
            <Avatar img={userId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900">Axtardığınız <span className="font-bold text-blue-700">{userId?.plateNumber}</span> qeydiyyat nişanı qeydiyyatdan keçdi.</p>
            </div>
        </div>,
    [NOTIFICATION_TYPE_MULTIPLE_USERS_VISITED_YOU]: ({count}) => <span>Sizin profilə {count} dəfə keçid olunub</span>,
    [NOTIFICATION_TYPE_USER_VERIFICATION_APPROVED]: ({ receiverId }) => <div className="flex gap-x-4 items-center">
            <Avatar img={receiverId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900">✅ Təsdiq nişanı müraciətiniz qəbul olundu.</p>
            </div>
        </div>,
    [NOTIFICATION_TYPE_USER_VERIFICATION_REJECTED]: ({ receiverId }) => <div className="flex gap-x-4 items-center">
            <Avatar img={receiverId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900">❌ Təsdiq nişanı müraciətiniz qəbul olunmadı.</p>
            </div>
        </div>,
    [NOTIFICATION_TYPE_LIKED_YOUR_POST]: ({userId}) => 
        <div className="flex gap-x-4 items-center">
            <Avatar img={userId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900"><span className="font-bold">{userId?.plateNumber}</span> sizin elanı bəyəndi.</p>
            </div>
        </div>,
    [NOTIFICATION_TYPE_COMMENTED_YOUR_POST]: ({userId}) => 
        <div className="flex gap-x-4 items-center">
            <Avatar img={userId?.photo} />
            <div className="min-w-0 max-w-64 flex-auto">
                <p className="text-sm leading-6 text-gray-900"><span className="font-bold">{userId?.plateNumber}</span> sizin elana rəy bildirdi.</p>
            </div>
        </div>,
}

export default notificationTypeMessages;