import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";

// Api
import { createPost } from "../../api/post";

// Components
import BottomNavigation from "../../components/BottomNavigation";

// Constants
import { POSTS } from "../../routing/constants";

const NewPostPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    content: yup.string().trim().required('Mətn tələb olunur'),
  });
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      content: '',
    },
  });

  const onSubmit = async data => {
    setIsLoading(true);
    try {
      const response = await createPost(data);
      if (response?.success) {
        reset();
        navigate(POSTS, { forceReload: true })
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      alert('Elanınız paylaşılmadı. Az sonra yenidən cəhd edin.');
    }
  };

  return (
    <div className="min-h-screen">
      <form className="min-h-screen" onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto max-w-4xl pt-8 px-2 lg:px-8 border-b-2">
          <h2 className="flex align-middle justify-between pl-3 sm:mt-0 mb-4 text-black text-2xl font-semibold">
            <span className="flex">
              <button className="mr-2" onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
              </button>{" "}
              Yeni elan
            </span>
            <button
              disabled={!isValid || isLoading}
              style={{ opacity: !isValid || isLoading ? 0.5 : 1 }}
              className="text-indigo-700 text-lg"
              onPress={handleSubmit(onSubmit)}
            >Paylaş</button>
          </h2>
        </div>
        <div className="px-2 py-2 relative bg-indigo-600 min-h-screen">
          <textarea
            {...register("content", { required: true })}
            type="text"
            id="name"
            className="min-h-screen text-white text-lg text-center block w-full p-2.5 border-none bg-transparent focus-visible:outline-none placeholder:text-indigo-300"
            placeholder="Elanınızı bura yazın..."
            required
          />
        </div>
      </form>
      <BottomNavigation />
    </div>
  );
};

export default NewPostPage;
