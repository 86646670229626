import React from 'react';
import { Link } from 'react-router-dom';

// Constants
import { LOGIN, REGISTRATION } from '../../routing/constants';

const LandingPage = () => {
    return <>
        <main className="bg-white flex flex-row items-center h-screen -mt-20">
            <div className="mx-auto max-w-md text-center">
                <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">Avtomobilin sahibini<br />axtarırsan?</h2>
                <p className="mt-6 text-lg leading-8 text-slate-700">Bu platformaya qoşulub həm səni axtaranlara tapmaqda kömək ola, həm də sən axtardıqlarını asanlıqla tapa bilərsən.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to={REGISTRATION} className="rounded-md bg-blue-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Qeydiyyat ol</Link>
                    <Link to={LOGIN} className="text-sm font-semibold leading-6 text-black">Daxil Ol <span aria-hidden="true">→</span></Link>
                </div>
            </div>
        </main>
        <footer className="bg-white rounded-md shadow m-2">
            <div className="w-full mx-auto max-w-screen-xl p-4">
                <ul className="flex flex-wrap items-center justify-between mt-0 text-sm font-medium text-gray-500">
                    <li>
                        <Link className="hover:underline me-4 md:me-6" target='_blank' to="https://www.privacypolicies.com/live/6a274b48-2bc5-4115-aa77-db1a56f50c18">Privacy Policy</Link>
                    </li>
                    <li>
                        <span className="text-sm text-gray-500">© 2024</span>
                    </li>
                </ul>
            </div>
        </footer>
    </>;
}

export default LandingPage;