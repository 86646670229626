import React from 'react';
import { NavLink } from "react-router-dom";

// Constants
import { HOME } from '../../routing/constants';

const Logo = ({ isDark, size }) => {
    let textSize = 'text-2xl';

    if (size === 'md') {
        textSize = 'text-5xl';
    }

    if (size === 'lg') {
        textSize = 'text-8xl';
    }

    return <NavLink to={HOME}>
        {/* <img className="mx-auto h-10 w-auto" src="logo192.png" alt="Your Company" /> */}
        <span className={`${textSize} ${isDark ? 'text-indigo-600' : 'text-indigo-100'} items-center mb-20 -mt-20 font-semibold`}>
            SahibiniTap
        </span>
    </NavLink>;
};

export default Logo;