import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { jwtDecode } from "jwt-decode";

// Components
import Avatar from "../../../components/Avatar";
import LoadingPlaceholder from "../../../components/LoadingPlaceholder";
import VerificationBadge from "../../../components/VerificationBadge";

// Api
import { createMessage, getMessages } from "../../../api/message/id";

// Constants
import { MY_PROFILE } from "../../../routing/constants";


const Conversation = ({ receiver }) => {
  const decoded = jwtDecode(localStorage.getItem("auth"));
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [responseData, setResponseData] = useState();
  const [sentMessages, setSentMessages] = useState([]);
  const { register, handleSubmit, reset, formState: { isValid } } = useForm();
  const { _id } = receiver;
  const scrollingDivRef = useRef();

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollingDivRef?.current) {
        scrollingDivRef.current.scrollTop = scrollingDivRef?.current?.scrollHeight;
      }
    }, 0);
  }

  const getData = async () => {
    const response = await getMessages(_id);
    setIsPageLoading(false);
    setResponseData(response?.data);
    scrollToBottom();
  };

  useEffect(() => {
    if (_id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSubmit = async (data) => {
    try {
      const tempRandomKey = (Math.random() + 1).toString(36).substring(7);
      setResponseData([...responseData, ...[{
        userId: decoded?._id, receiverId: _id, message: data?.message, tempRandomKey
      }]]);
      scrollToBottom();
      reset();
      const response = await createMessage(_id, data);
      setSentMessages([...sentMessages, ...[{_id: response.data?._id, tempRandomKey}]])
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const updatedResponseData = sentMessages?.reduce((acc, current) => {
      const updatedResponseData = acc?.map(
        (message) => message.tempRandomKey === current.tempRandomKey ?
          {...message, _id: current?._id} :
          message 
      )
      return updatedResponseData;
    }, responseData);

    setResponseData(updatedResponseData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentMessages]);

  return (
    <div className="lg:col-span-2 lg:block">
      <div className="relative w-full z-0 min-h-screen">
        <div className="relative flex items-center p-3 border-b border-gray-300">
          <NavLink to={`${MY_PROFILE}/${receiver._id}`} className="flex items-center">
            <Avatar img={receiver?.photo} />
            <span className="block ml-2 font-bold text-gray-600">
              {receiver?.plateNumber} <VerificationBadge classNames="-mt-1" verification={receiver?.verification} />
            </span>
          </NavLink>
          {/* <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3"></span> */}
        </div>
        <div ref={scrollingDivRef} className="relative w-full p-6 overflow-y-auto h-[28rem] z-0">
          {isPageLoading && <LoadingPlaceholder />} 
          {!isPageLoading && <ul className="space-y-2">
            {responseData?.map(message => {
              const isSender = message.userId === decoded?._id;

              return <li key={message._id || message.tempRandomKey} className={`flex justify-${!isSender ? 'start' : 'end'}`}>
                <div className={`relative max-w-xl px-4 py-2 ${!message._id ? 'text-gray-400' : 'text-gray-700'} rounded shadow ${isSender ? 'bg-gray-100' : ''} `}>
                  <span className="block">{message.message}</span>
                </div>
              </li>;
            })}
          </ul>}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}className="flex items-center justify-between w-full p-3 border-t border-gray-300 absolute bottom-28 bg-white">

          <input
            {...register("message", {required: true})}
            type="text"
            placeholder="Mesajınızı bura yazın..."
            className="block w-full py-2 pl-4 mx-3 rounded-full outline-none bg-indigo-50 border border-indigo-300 text-indigo-900 focus:text-indigo-700 focus:border-indigo-500"
            name="message"
            autoComplete="off"
            required
          />
          <button
            disabled={!isValid}
            type="submit"
            className="text-indigo-500 disabled:text-gray-500"
          >
            <svg
              className="w-5 h-5 origin-center transform rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Conversation;
