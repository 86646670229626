import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// Api
import { getVerification } from "../../api/user/verification";
import { getUserBadges } from "../../api/user/badges";

// Constants
import { HOME, MESSAGES, MY_PROFILE, NOTIFICATIONS, POSTS } from "../../routing/constants";

const tabs = [
  {
    icon: (
      <svg
        className="w-5 h-5 mb-2 group-hover:text-blue-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
      </svg>
    ),
    route: HOME,
  },
  {
    icon: (
      <svg
        className="w-5 h-5 mb-2 group-hover:text-blue-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z"></path>
      </svg>
    ),
    route: POSTS,
  },
  {
    // icon: <svg className="w-5 h-5 mb-2 group-hover:text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
    //     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 14 3-3m-3 3 3 3m-3-3h16v-3m2-7-3 3m3-3-3-3m3 3H3v3"></path>
    // </svg>,
    // route: '/exchange'
    icon: (
      <svg
        className="w-5 h-5 mb-2 group-hover:text-blue-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path d="M18 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.546l3.2 3.659a1 1 0 0 0 1.506 0L13.454 14H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-8 10H5a1 1 0 0 1 0-2h5a1 1 0 1 1 0 2Zm5-4H5a1 1 0 0 1 0-2h10a1 1 0 1 1 0 2Z"></path>
      </svg>
    ),
    route: MESSAGES,
  },
  {
    icon: (
      <svg
        className="w-5 h-5 mb-2 group-hover:text-blue-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M15.133 10.632v-1.8a5.407 5.407 0 0 0-4.154-5.262.955.955 0 0 0 .021-.106V1.1a1 1 0 0 0-2 0v2.364a.944.944 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C4.867 13.018 3 13.614 3 14.807 3 15.4 3 16 3.538 16h12.924C17 16 17 15.4 17 14.807c0-1.193-1.867-1.789-1.867-4.175Zm-13.267-.8a1 1 0 0 1-1-1 9.424 9.424 0 0 1 2.517-6.39A1.001 1.001 0 1 1 4.854 3.8a7.431 7.431 0 0 0-1.988 5.037 1 1 0 0 1-1 .995Zm16.268 0a1 1 0 0 1-1-1A7.431 7.431 0 0 0 15.146 3.8a1 1 0 0 1 1.471-1.354 9.425 9.425 0 0 1 2.517 6.391 1 1 0 0 1-1 .995ZM6.823 17a3.453 3.453 0 0 0 6.354 0H6.823Z"></path>
      </svg>
    ),
    route: NOTIFICATIONS,
  },
  {
    icon: (
      <svg
        className="w-5 h-5 mb-2 group-hover:text-blue-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
      </svg>
    ),
    route: MY_PROFILE,
  },
];

const BottomNavigation = () => {
  const location = useLocation();

  const decoded = jwtDecode(localStorage.getItem("auth"));
  const [badges, setBadges] = useState({});

  const getBadges = async () => {
    const response = await getUserBadges();
    setBadges(response?.data || {});
  };

  const updateTokenIfVerificationApproved = async () => {
    if (decoded?.verification !== "sent") {
      return;
    }
    const response = await getVerification();
    if (response?.token) localStorage.setItem("auth", response?.token);
  };

  useEffect(() => {
    getBadges();
    updateTokenIfVerificationApproved();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium">
        {tabs?.map(({ icon, route }) => (
          <NavLink
            to={route}
            className={`group relative inline-flex flex-col items-center justify-center px-5  hover:bg-gray-50 ${
              location.pathname === route ? "bg-gray-50 text-blue-600" : "text-gray-500"
            }`}
          >
            {icon}

			{[MESSAGES, NOTIFICATIONS].includes(route) && badges[route.substring(1)] > 0 && <span className="w-5 h-5 absolute top-1 right-6 bg-red-600 text-center align-middle inline-block text-xs text-white rounded-full">{badges[route.substring(1)]}</span>}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BottomNavigation;
