import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { jwtDecode } from "jwt-decode";

// Components
import SettingsPageTemplate from "../../SettingsPageTemplate";

// Api
import { getVerification, putVerification } from "../../../../api/user/verification";

// Constants
import messages from "./constants/messages";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";

const VerificationPage = () => {
  const decoded = jwtDecode(localStorage.getItem("auth"));
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm();
  const [responseData, setResponseData] = useState({
    plateNumber: decoded?.plateNumber,
    verification: decoded?.verification,
  });
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getData = async () => {
    const response = await getVerification();
    setIsPageLoading(false);
    if (response?.data?.token) {
      localStorage.setItem("auth", response?.data?.token);
    }
    setResponseData(response?.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("doc1", data.doc1[0]);
      const response = await putVerification(formData);
      
      if (response?.token) {
        localStorage.setItem('auth', response?.token);
        sessionStorage.removeItem('hasSeenVerificationModal');
      }

      setResponseData(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getStatus = () => {
    if (responseData?.verification === "approved") {
      return (
        <div
          className="mb-4 mt-2 rounded bg-green-100 px-6 py-5 text-base text-green-700"
          role="alert"
        >
          <span className="font-bold">Təsdiqlənmişdir!</span> <br />{" "}
          <br /> Təbrik edirik. Sizin təsdiq nişanı artıq profilinizdə öz əksini tapır.
        </div>
      );
    }

    if (responseData?.verification === "sent") {
      return (
        <div
          className="mb-4 mt-2 rounded bg-green-100 px-6 py-5 text-base text-green-700"
          role="alert"
        >
          <span className="font-bold">Sorğunuz göndərilmişdir!</span> <br />{" "}
          <br /> Məlumatlarınız moderator tərəfindən tezliklə yoxlanılacaq.
        </div>
      );
    }

    if (responseData?.verification === "rejected") {
      return (
        <div
          className="mb-4 mt-2 rounded bg-red-100 px-6 py-5 text-base text-red-700"
          role="alert"
        >
          <span className="font-bold">Müraciətiniz təsdiq olunmadı!</span>{" "}
          <br /> <br /> Səbəb: {messages[responseData?.rejectReason]}
        </div>
      );
    }
  };

  return (
    <SettingsPageTemplate title="Təsdiq nişanı">
      <div className="grid max-w-2xl mx-auto mt-4">
        <div className={`${responseData?.verification === 'approved' ? 'text-green-700' : 'text-gray-700'} mx-auto`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-36 h-36"
          >
            <path
              fillRule="evenodd"
              d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {getStatus()}
        {isPageLoading && <LoadingPlaceholder />}
        {!isPageLoading && (!responseData?.verification || responseData?.verification === 'rejected') ? <>
          <div className="mb-2 rounded text-indigo-900 px-6 py-5 text-base bg-indigo-200" role="alert">
            <p className="text-md text-balance">
              <b>{decoded?.plateNumber}</b> qeydiyyat nişanına
              uyğun <b>texpasport</b>-un ön tərəfinin şəklini aşağıdakı form ilə göndərin.
            </p>
          </div>
          <form
            className="items-center mt-4 sm:mt-7 text-[#202142]"
            onSubmit={handleSubmit(onSubmit)}
          >
          <div className="flex flex-col items-center w-full mb-0 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block mb-0 text-md font-medium text-indigo-900 sm:text-sm"
              >
                Texpasportun şəkli (ön tərəf):
              </label>
              <input
                {...register("doc1", { required: true })}
                disabled={isSubmitting}
                name="doc1"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                required
                className="block w-64 rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="flex justify-end mt-6 sm:mt-0">
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              type="submit"
              className={`${
                !isDirty || !isValid || isSubmitting
                  ? "disabled:bg-gray-400 hover:bg-gray-400"
                  : ""
              } text-white bg-indigo-700  hover:bg-indigo-800 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
            >
              {isSubmitting ? "Göndərilir..." : "Göndər"}
            </button>
          </div>
          </form>
        </> : null}
      </div>
    </SettingsPageTemplate>
  );
};

export default VerificationPage;
