import React from 'react';

const getTextColor = (verification, isAdmin) => {
    let color = 'text-gray-500';

    if (isAdmin) {
        if (verification === 'approved') {
            color = 'text-green-700';
        } else if (verification === 'rejected') {
            color = 'text-red-700';
        } else if (verification === 'sent') {
            color = 'text-blue-700';
        }
    } else {
        if (verification === 'approved') {
            color = 'text-green-700';
        }
    }
    return color;
}

const getSize = (size) => {
    let svgSize = '6';
    if (size === 'md') {
        svgSize = '12';
    }
    if (size === 'lg') {
        svgSize = '36';
    }
    return svgSize;
}

const VerificationBadge = ({ classNames, size, verification, isAdmin }) => {
    

    return <svg
        className={`w-${getSize(size)} h-${getSize(size)} ${getTextColor(verification, isAdmin)} inline-block align-middle ${classNames}`}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
    >
        <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
    </svg>
}

export default VerificationBadge;