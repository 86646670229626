import React from "react";

// Templates
import SettingsPageTemplate from "../../SettingsPageTemplate";

const ChangePasswordPage = () => {
//   const navigate = useNavigate();
//   const {
//     register,
//     watch,
//     handleSubmit,
//     formState: { errors, isSubmitting, isDirty, isValid },
//     control,
//   } = useForm();
//   const decoded = jwtDecode(localStorage.getItem("auth"));
//   const [responseData, setResponseData] = useState({
//     plateNumber: decoded?.plateNumber,
//     verification: decoded?.verification,
//   });
//   const [isPageLoading, setIsPageLoading] = useState(true);

//   const getData = async () => {
//     const response = await getUserMe();
//     setIsPageLoading(false);
//     setResponseData(response?.data);
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   const onSubmit = async (data) => {
//     try {
//       const formData = new FormData();
//       formData.append("photo", data.photo[0]);
//       const response = await putUserMe(formData);
//       setResponseData(response?.data);
//     } catch (e) {
//       console.log(e);
//     }
//   };

  return (
    <SettingsPageTemplate title="Təhlükəsizlik">
      <div className="grid max-w-2xl mx-auto">

        <div className="items-center sm:mt-14 text-[#202142]">

          <div className="mb-2 sm:mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white"
            >
              Cari Şifrə
            </label>
            <input
              type="email"
              id="email"
              className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
              placeholder="your.email@mail.com"
              required
            />
          </div>
          <div className="mb-2 sm:mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white"
            >
              Şifrə
            </label>
            <input
              type="email"
              id="email"
              className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
              placeholder="your.email@mail.com"
              required
            />
          </div>

          <div className="mb-2 sm:mb-6">
            <label
              htmlFor="profession"
              className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white"
            >
              Şifrənin təkrarı
            </label>
            <input
              type="text"
              id="profession"
              className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
              placeholder="your profession"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </SettingsPageTemplate>
  );
};

export default ChangePasswordPage;
