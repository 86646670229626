import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';

// Components
import Navbar from '../../components/Navbar';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

// Constants
import { HOME } from '../../routing/constants';
import messages from './constants/messages';

// Api
import { getVerification, putVerification } from '../../api/user/verification';

const DocumentConfirmationPage = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { isSubmitting, isDirty, isValid } } = useForm();
    const decoded = jwtDecode(localStorage.getItem('auth'));
    const [responseData, setResponseData] = useState({ plateNumber: decoded?.plateNumber, verification: decoded?.verification });
    const [isPageLoading, setIsPageLoading] = useState(true);

    const getData = async () => {
        const response = await getVerification();
        setIsPageLoading(false);
        if (response?.data?.verification === 'approved') {
            localStorage.setItem('auth', response?.data?.token);
            navigate(HOME)
        }

        setResponseData(response?.data);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async data => {
        try {
            const formData = new FormData();
            formData.append("doc1", data.doc1[0]);
            const response = await putVerification(formData);
            setResponseData(response?.data);
        } catch (e) {
            console.log(e);
        }
    };

    const getStatus = () => {
        if (!responseData?.verification) {
            return <div className="mb-4 rounded bg-gray-100 px-6 py-5 text-base text-gray-700" role="alert">Saytın bütün funksiyalarından istifadə etmək üçün hesabınızı təsdiqləmək mütləqdir. <br /> Bunun üçün aşağıda tələb olunan sənədləri göndərin.</div>
        }
        
        if (responseData?.verification === 'sent') {
            return <div className="mb-4 rounded bg-green-100 px-6 py-5 text-base text-green-700" role="alert">
                <span className="font-bold">Sənədləriniz Göndərildi!</span> <br /> <br /> Ən gec 24 saat ərzində yoxlanılıb, hesabınız yenilənəcək. <br /> Səhifəni mütəmadi olaraq yeniləməyi unutmayın.
            </div>
        }

        if (responseData?.verification === 'rejected') {
            return <div className="mb-4 rounded bg-red-100 px-6 py-5 text-base text-red-700" role="alert">
                <span className="font-bold">Müraciətiniz təsdiq olunmadı!</span> <br /> <br /> Səbəb: {messages[responseData?.rejectReason]}</div>
        }
    }

    return <div className="min-h-full">
        <Navbar />
        <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Sahibinin təsdiqi</h1>
        </div>
        </header>
        <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                {isPageLoading && <LoadingPlaceholder />}
                {!isPageLoading && <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="px-4 sm:px-0">
                        {getStatus()}
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
                    </div>
                    {responseData?.verification !== 'sent' && <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Dövlət Qeydiyyat Nişanı</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 uppercase">{responseData?.plateNumber}</dd>
                            </div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Texpasportun şəkli</dt>
                                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                            <div className="flex w-0 flex-1 items-center">
                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clipRule="evenodd" />
                                            </svg>
                                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                    <input
                                                        {...register("doc1", {required: true})}
                                                        disabled={isSubmitting}
                                                        name="doc1"
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        required
                                                        className="block rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>}
                    {responseData?.verification !== 'sent' && <div>
                        <button
                            disabled={!isDirty || !isValid || isSubmitting}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 my-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-400"
                        >
                            {isSubmitting && <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 my-auto text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                            </svg>}
                            {isSubmitting ? 'Göndərilir...' : 'Göndər'}
                        </button>
                    </div>}
                </form>}  
            </div>
        </main>
    </div>;
}

export default DocumentConfirmationPage;
