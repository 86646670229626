import React from 'react';
import env from "react-dotenv";

import setAxiosDefaults from './api/axiosInstance';
import AppRoutes from './routing/AppRoutes';

function App() {
  setAxiosDefaults(env.API_URL);
  return (
    <AppRoutes />
  );
}

export default App;
