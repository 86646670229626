import React from "react";
import { NavLink } from "react-router-dom";

// Constants
import { MY_PROFILE } from "../../../routing/constants";
import Avatar from "../../../components/Avatar";
import VerificationBadge from "../../../components/VerificationBadge";

const FavoriteResults = ({ items }) => {

  if (!Array.isArray(items) || items.length < 1) {
    return (
      <div
        className="mt-4 rounded bg-indigo-100 px-6 mx-4 py-5 text-base text-indigo-700"
        role="alert"
      >
        Sizin heç bir favorit qeydiyyat nişanınız yoxdur.
      </div>
    );
  }

  return (
    <>
      <ul className="border-y mt-4 divide-y divide-gray-100">
        {Array.isArray(items) &&
          items?.map((item) => (
            <li
              key={item?.favoriteUserId?._id}
              className={`flex justify-between gap-x-6 py-5 px-4 hover:bg-indigo-50 cursor-pointer`}
            >
              <NavLink
                to={`${MY_PROFILE}/${item?.favoriteUserId?._id}`}
                className="flex min-w-0 gap-x-4"
              >
                <Avatar img={item?.favoriteUserId?.photo} />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {item?.favoriteUserId?.plateNumber}{" "}
                    <VerificationBadge
                      classNames="-mt-1"
                      verification={item?.verification}
                    />
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {item?.favoriteUserId?.name}
                  </p>
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
    </>
  );
};

export default FavoriteResults;
