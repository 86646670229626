import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { jwtDecode } from "jwt-decode";

// Components
import Avatar from "../../../../components/Avatar";
import SettingsPageTemplate from "../../SettingsPageTemplate";

// Api
import { putUserMe } from "../../../../api/user/me";
import { deleteUserPhoto, putUserPhoto } from "../../../../api/user/photo";

const EditProfilePage = () => {
  const decoded = jwtDecode(localStorage.getItem("auth"));
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm({
    defaultValues: decoded
  });
  const [isPhotoReplacing, setIsPhotoReplacing] = useState(false);

  const onSubmit = async (data) => {
    try {
      const response = await putUserMe(data);
      localStorage.setItem('auth', response?.token);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeletePhoto = async () => {
    try {
      if (window.confirm('Profil şəklinizi silmək istədiyinizdən əminsinizmi?') === true) {
        setIsPhotoReplacing(true);
        const response = await deleteUserPhoto();
        localStorage.setItem('auth', response?.token);
        setIsPhotoReplacing(false);
      }
    } catch (e) {
      console.log(e);
      setIsPhotoReplacing(false);
    }
  }

  const handleClickChangePhoto = () => {
    document.getElementById("photo").click();
  }

  const handleChangePhotoInput = async (e) => {
    try {
      setIsPhotoReplacing(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("photo", file);
      const response = await putUserPhoto(formData);
      localStorage.setItem('auth', response?.token);
      setIsPhotoReplacing(false);
    } catch (e) {
        console.log(e);
        setIsPhotoReplacing(false);
    } 
  }

  return (
    <SettingsPageTemplate title="Profil məlumatları">
      <div className="grid max-w-2xl mx-auto mt-8">
        <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
          <div className="object-cover p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500">
            <Avatar size="md" img={decoded?.photo} />
          </div>

          <div className="flex flex-col space-y-5 sm:ml-8">
            <button
              disabled={isPhotoReplacing}
              onClick={handleClickChangePhoto}
              type="button"
              className="py-3.5 px-7 text-base font-medium text-indigo-100 focus:outline-none bg-[#202142] rounded-lg border border-indigo-200 hover:bg-indigo-900 focus:z-10 focus:ring-4 focus:ring-indigo-200 disabled:bg-gray-400 cursor-pointer"
            >
              {isPhotoReplacing ? 'Yüklənir...' : 'Şəkli dəyiş'}
            </button>

            <input onChange={handleChangePhotoInput} type="file" id="photo" className="hidden" />

            {decoded?.photo && <button
              disabled={isPhotoReplacing}
              onClick={handleDeletePhoto}
              type="button"
              className="py-3.5 px-7 text-base font-medium text-indigo-900 focus:outline-none bg-white rounded-lg border border-indigo-200 hover:bg-indigo-100 hover:text-[#202142] focus:z-10 focus:ring-4 focus:ring-indigo-200 disabled:bg-gray-400 disabled:text-white cursor-pointer"
            >
              {isPhotoReplacing ? 'Yüklənir...' : 'Şəkli sil'}
            </button>}
          </div>
        </div>

        <form className="items-center mt-4 sm:mt-7 text-[#202142]" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center w-full mb-0 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <label
                htmlFor="name"
                className="block mb-0 text-sm font-medium text-indigo-900 dark:text-white"
              >
                ad soyad
              </label>
              <input
                {...register("name", {required: true,  minLength: 6})}
                type="text"
                id="name"
                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                placeholder="ad soyad"
                required
              />
            </div>
          </div>

          <div className="flex flex-col items-center w-full space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white"
              >
                mobil nömrə
              </label>

              <Controller
                control={control}
                name="phone"
                render = {()=> (
                    <InputMask
                        {...register("phone", {
                          required: true,
                          pattern: /0\d\d-\d\d\d-\d\d\d\d/
                        })}
                        mask="099-999-9999"
                        placeholder="mobil nömrə"
                        className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                    />
                )}
              />
            </div>

            <div className="w-full items-center sm:h-0">
              <div className="flex items-center">
                  <input {...register("isPhonePublic", {required: false})} id="checked-checkbox-phone" type="checkbox" className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 focus:ring-2" />
                  <label htmlFor="checked-checkbox-phone" className="ms-2 text-sm font-medium text-gray-800">Profilimdə görsənsin</label>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
            <div className="w-full">
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-indigo-900 dark:text-white"
              >
                email
              </label>
              <input
                {...register("email", {required: false})}
                type="email"
                id="email"
                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                placeholder="email"
              />
            </div>

            <div className="w-full items-center sm:h-0">
              <div className="flex items-center">
                  <input {...register("isEmailPublic", {required: false})} id="checked-checkbox-email" type="checkbox" className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 focus:ring-2" />
                  <label htmlFor="checked-checkbox-email" className="ms-2 text-sm font-medium text-gray-800">Profilimdə görsənsin</label>
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-6 sm:mt-0">
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              type="submit"
              className={`${!isDirty || !isValid || isSubmitting ? 'disabled:bg-gray-400 hover:bg-gray-400' : ''} text-white bg-indigo-700  hover:bg-indigo-800 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
            >
              {isSubmitting ? 'Gözləyin...' : 'Yadda saxla'}
            </button>
          </div>
        </form>
      </div>
    </SettingsPageTemplate>
  );
};

export default EditProfilePage;
