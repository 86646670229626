import React from "react";

// Components
import AsideMenu from "./components/AsideMenu";
import BottomNavigation from "../../components/BottomNavigation";

const SettingsPageTemplate = ({ title, children }) => {
  return (
    <div className="min-h-full">
        <div className="py-8 bg-white w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
            <AsideMenu />
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4">
                <div className="p-2 md:p-4">
                    <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                        <h2 className="text-2xl font-bold sm:text-xl">{title}</h2>
                        {children}
                    </div>
                </div>
            </main>
        </div>
        <BottomNavigation />
    </div>
  );
};

export default SettingsPageTemplate;
