import React from 'react';
import { NavLink } from 'react-router-dom';

// Constants
import { SETTINGS, VERIFICATION } from '../../../../routing/constants';

const AsideMenu = () => {
    return <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
        <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-indigo-100 top-12">

            <h2 className="pl-3 mb-4 text-2xl font-semibold">Tənzimləmələr</h2>

            <NavLink
                to={SETTINGS}
                className={({ isActive }) =>
                    `${isActive && 'border rounded-full'} flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900`
                }
            >
                Profil məlumatları
            </NavLink>
            <NavLink
                to={VERIFICATION}
                className={({ isActive }) =>
                    `${isActive && 'border rounded-full'} flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900`
                }
            >
                Təsdiq nişanı
            </NavLink>
            {/* <NavLink
                to={CHANGE_PASSWORD}
                className={({ isActive }) =>
                    `${isActive && 'border rounded-full'} flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900`
                }
            >
                Təhlükəsizlik
            </NavLink> */}
        </div>
    </aside>;
};

export default AsideMenu;
