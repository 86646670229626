import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal from '../../../components/Modal';

// Constants
import { VERIFICATION } from '../../../routing/constants';

const VerificationModal = ({onClose}) => {
    const body = <div className="mt-6">
        <div className="mb-4 rounded text-indigo-900 px-6 py-5 text-base bg-indigo-200" role="alert">
            <span className="inline-block align-middle">
                Daha çox axtarış etmək üçün zəhmət olmasa hesabınızı təsdiq edin.
            </span>
        </div>
    </div>

    return <Modal 
        title="Axtarış limitini keçdiniz"
        body={body}
        buttons={
            <>
                <Link to={VERIFICATION} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto">Təsdiq et</Link>
                <button onClick={onClose} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Sonra</button>
            </>
        }
    />
}

export default VerificationModal;