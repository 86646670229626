import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Modal from '../../components/Modal';

// Constants
import { LOGIN } from '../../routing/constants';

const UserExistsModal = ({onClose}) => {
    
    const body = <form>
        <div>
            <p className="text-sm text-gray-500">
                Daxil etdiyiniz məlumatlarla hesab mövcuddur. <br />Zəhmət olmasa "Daxil ol" səhifəsinə keçid edib, sistemə daxil olasınız.
            </p>
        </div>
    </form>

    return <Modal 
        title="Qeydiyyat"
        body={body}
        buttons={
            <>
                <Link to={LOGIN} type="button" className="inline-flex w-full justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto">Daxil Ol</Link>
                <button onClick={onClose} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">İmtina</button>
            </>
        }
    />
}

export default UserExistsModal;